.hll {
  background-color: #ffc;
}

.c {
  color: #999;
}

// Comment
.err {
  background-color: #FFAAAA;
  color: #AA0000;
}

// Error
.k {
  color: #006699;
}

// Keyword
.o {
  color: #555555;
}

// Operator
.cm {
  color: #0099FF;
  font-style: italic;
}

// Comment.Multiline
.cp {
  color: #009999;
}

// Comment.Preproc
.c1 {
  color: #999;
}

// Comment.Single
.cs {
  color: #999;
}

// Comment.Special
.gd {
  border: 1px solid #CC0000;
  background-color: #FFCCCC;
}

// Generic.Deleted
.ge {
  font-style: italic;
}

// Generic.Emph
.gr {
  color: #FF0000;
}

// Generic.Error
.gh {
  color: #003300;
}

// Generic.Heading
.gi {
  border: 1px solid #00CC00;
  background-color: #CCFFCC;
}

// Generic.Inserted
.go {
  color: #AAAAAA;
}

// Generic.Output
.gp {
  color: #000099;
}

// Generic.Prompt
.gs {}

// Generic.Strong
.gu {
  color: #003300;
}

// Generic.Subheading
.gt {
  color: #99CC66;
}

// Generic.Traceback
.kc {
  color: #006699;
}

// Keyword.Constant
.kd {
  color: #006699;
}

// Keyword.Declaration
.kn {
  color: #006699;
}

// Keyword.Namespace
.kp {
  color: #006699;
}

// Keyword.Pseudo
.kr {
  color: #006699;
}

// Keyword.Reserved
.kt {
  color: #007788;
}

// Keyword.Type
.m {
  color: #FF6600;
}

// Literal.Number
.s {
  color: #d44950;
}

// Literal.String
.na {
  color: #4f9fcf;
}

// Name.Attribute
.nb {
  color: #336666;
}

// Name.Builtin
.nc {
  color: #00AA88;
}

// Name.Class
.no {
  color: #336600;
}

// Name.Constant
.nd {
  color: #9999FF;
}

// Name.Decorator
.ni {
  color: #999999;
}

// Name.Entity
.ne {
  color: #CC0000;
}

// Name.Exception
.nf {
  color: #CC00FF;
}

// Name.Function
.nl {
  color: #9999FF;
}

// Name.Label
.nn {
  color: #00CCFF;
}

// Name.Namespace
.nt {
  color: #2f6f9f;
}

// Name.Tag
.nv {
  color: #003333;
}

// Name.Variable
.ow {
  color: #000000;
}

// Operator.Word
.w {
  color: #bbbbbb;
}

// Text.Whitespace
.mf {
  color: #FF6600;
}

// Literal.Number.Float
.mh {
  color: #FF6600;
}

// Literal.Number.Hex
.mi {
  color: #FF6600;
}

// Literal.Number.Integer
.mo {
  color: #FF6600;
}

// Literal.Number.Oct
.sb {
  color: #CC3300;
}

// Literal.String.Backtick
.sc {
  color: #CC3300;
}

// Literal.String.Char
.sd {
  color: #CC3300;
  font-style: italic;
}

// Literal.String.Doc
.s2 {
  color: #CC3300;
}

// Literal.String.Double
.se {
  color: #CC3300;
}

// Literal.String.Escape
.sh {
  color: #CC3300;
}

// Literal.String.Heredoc
.si {
  color: #AA0000;
}

// Literal.String.Interpol
.sx {
  color: #CC3300;
}

// Literal.String.Other
.sr {
  color: #33AAAA;
}

// Literal.String.Regex
.s1 {
  color: #CC3300;
}

// Literal.String.Single
.ss {
  color: #FFCC33;
}

// Literal.String.Symbol
.bp {
  color: #336666;
}

// Name.Builtin.Pseudo
.vc {
  color: #003333;
}

// Name.Variable.Class
.vg {
  color: #003333;
}

// Name.Variable.Global
.vi {
  color: #003333;
}

// Name.Variable.Instance
.il {
  color: #FF6600;
}

// Literal.Number.Integer.Long

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}
